import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contactform from "../components/contactform"
import Img from "gatsby-image"
import Obfuscate from "react-obfuscate"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            phone
            email
          }
        }
        file(relativePath: { eq: "contacto-cerezo-rosa.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Contacto" description="Contacto Cerezo Rosa" />

      <section className="jumbotron breadcumb">
        <div className="mainbreadcumb">
          <div className="row m-10-hor page-title">
            <div className="col-md-12">
              <ol itemscope itemtype="https://schema.org/BreadcrumbList">
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <Link
                    to="/"
                    title={data.site.siteMetadata.description}
                    itemprop="item"
                  >
                    <span itemprop="name">{data.site.siteMetadata.title}</span>
                  </Link>
                  <meta itemprop="position" content="1" />
                </li>
                <li
                  itemprop="itemListElement"
                  itemscope
                  itemtype="https://schema.org/ListItem"
                >
                  <Link to="/" itemprop="item" title="Contacto">
                    <span itemprop="name">Contacto</span>
                  </Link>
                  <meta itemprop="position" content="2" />
                </li>
              </ol>
              <h1>Contacto</h1>
            </div>
          </div>
        </div>
        <div id="cover">
          <Img
            imgStyle={{
              width: `100%`,
              height: `100%`,
              objectFit: `cover`,
              position: `absolute`,
            }}
            fluid={data.file.childImageSharp.fluid}
            alt={"Contacto " + data.site.siteMetadata.title}
          />
        </div>
        {/* <div className="icon-scroll-wrapper">
          <div className="icon-scroll">
            <div className="icon-scroll-screen"></div>
          </div>
        </div> */}
      </section>
      <section id="contact">
        <div className="row m-10-hor">
          {/* <div className="col-md-6">
            <div className="form-side">
              <h2>&iexcl;Rueda en Rosa!</h2>
              <p>
                Puedes ponerte en contacto con nosotros por medio del siguiente
                formulario o a trav&eacute;s de nuestras direcciones y
                tel&eacute;fono.
              </p>
              <Contactform />
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="text-side">
              <div className="address">
                <h3 className="heading">Direcciones y tel&eacute;fono</h3>
                <ul>
                  <li>
                    <i className="fa fa-map-marker"></i>
                    <a
                      href="//goo.gl/maps/V3GvSAFA64ymCxW8A"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Traves&iacute;a de la Palmera 18, 28500 Arganda del Rey
                    </a>
                  </li>
                  <li>
                    <i className="fa fa-envelope-o"></i>
                    <Obfuscate email={data.site.siteMetadata.email} />
                  </li>
                  <li>
                    <i class="fa fa-whatsapp" aria-hidden="true"></i>
                    <Obfuscate
                      className="phone"
                      tel={data.site.siteMetadata.phone}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
