import React from "react"
import { Link } from "gatsby"
import axios from "axios"
import * as qs from "query-string"

const cool = (
  <div className="msg-confirm">
    <h3>&iexcl;Gracias!</h3>
    <p>Contactaremos contigo de vuelta lo antes posible.</p>
  </div>
)

const oops = (
  <div className="msg-error">
    <h3>&iexcl;Oops!</h3>
    <p>Parece que algo ha fallado.</p>
  </div>
)

class contactform extends React.Component {
  constructor(props) {
    super(props)
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null }
  }

  handleSubmit(event) {
    // Do not submit form via HTTP, since we're doing that via XHR request.
    event.preventDefault()
    // Loop through this component's refs (the fields) and add them to the
    // formData object. What we're left with is an object of key-value pairs
    // that represent the form data we want to send to Netlify.
    const formData = {}
    Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value))

    // Set options for axios. The URL we're submitting to
    // (this.props.location.pathname) is the current page.
    const axiosOptions = {
      //url: this.props.location.pathname,
      url: window.location.href,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    }

    // Submit to Netlify. Upon success, set the feedback message and clear all
    // the fields within the form. Upon failure, keep the fields as they are,
    // but set the feedback message to show the error state.
    axios(axiosOptions)
      .then(response => {
        this.setState({
          feedbackMsg: cool,
        })
        this.domRef.current.reset()
      })
      .catch(err =>
        this.setState({
          feedbackMsg: oops,
        })
      )
  }

  render() {
    return (
      <>
        <form
          ref={this.domRef}
          className="formcontact"
          onSubmit={event => this.handleSubmit(event)}
          method="POST"
          name="contacto"
          data-netlify="true"
          data-splitbee-event="Envío de Formulario"
          data-splitbee-event-type="Contacto"
        >
          <input
            ref="form-name"
            type="hidden"
            name="form-name"
            value="contacto"
          />
          <label htmlFor="nombre">
            <h5>Nombre</h5>
            <input
              ref="nombre"
              type="text"
              name="nombre"
              placeholder="Tu nombre"
              required
            />
          </label>

          <label htmlFor="email">
            <h5>Email</h5>
            <input
              ref="email"
              type="email"
              name="email"
              placeholder="nombre@dominio"
              required
            />
          </label>

          <label htmlFor="mensaje">
            <h5>Mensaje</h5>
            <textarea
              ref="mensaje"
              name="mensaje"
              rows="3"
              placeholder="Tu mensaje"
              required
            />
          </label>

          <label>
            <input
              ref="acceptance"
              type="checkbox"
              placeholder="Debe aceptar la política de privacidad"
              value=""
              required
            ></input>
            {/* <span class="checkmark"></span> */}
            <small>
              Acepto el{" "}
              <a href="/aviso-legal/" target="_blank">
                Aviso legal
              </a>{" "}
              y la{" "}
              <a href="/politica-privacidad/" target="_blank">
                Política de Privacidad
              </a>
            </small>
          </label>

          <div className="submit-wrapper">
            <button className="btn" type="submit">
              Enviar
            </button>
          </div>
        </form>
        {this.state.feedbackMsg && <p>{this.state.feedbackMsg}</p>}
      </>
    )
  }
}

export default contactform
